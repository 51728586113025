<template>
  <!-- preloader ==================== -->
  <div v-if="preloaderVisible" ref="preloader" id="preloader">
    <div ref="loader" id="loader" class="dots-fade">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <!-- ==================== -->
</template>

<script setup>
import { ref, onMounted } from "vue";

// State and DOM references
const preloaderVisible = ref(true);
const preloader = ref(null);

onMounted(() => {
  // Keep the preloader visible for 2 seconds
  setTimeout(() => {
    if (preloader.value) {
      preloader.value.classList.add("fade-out"); // Trigger fade-out effect
    }

    setTimeout(() => {
      preloaderVisible.value = false; // Remove preloader from DOM
    }, 500); // Match CSS fade-out duration
  }, 2000); // Show preloader for 2 seconds
});
</script>

<style lang="scss" scoped>
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1d1d1d;
  z-index: 500;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.5s ease; /* Smooth fade-out transition */
}

#preloader.fade-out {
  opacity: 0;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 6px;
  height: 6px;
  display: inline-block;
  transform: translate3d(-50%, -50%, 0);
}

#loader > div {
  background: #ffffff;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

#loader > div:nth-of-type(1) {
  left: 15px;
}

#loader > div:nth-of-type(3) {
  left: -15px;
}

/* dots fade */
.dots-fade > div {
  animation: dots-fade 1.6s infinite ease;
}

.dots-fade > div:nth-of-type(1) {
  animation-delay: 0.8s;
}

.dots-fade > div:nth-of-type(3) {
  animation-delay: 0s;
}

@keyframes dots-fade {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  80% {
    opacity: 1;
  }
}
</style>
