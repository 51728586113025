<template>
  <!-- site header ==================== -->
  <header class="s-header">
    <div class="header-logo">
      <a class="site-logo" href="index.html">
        <img src="images/logo.svg" alt="Homepage" />
      </a>
    </div>

    <div class="header-email">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z"
        />
      </svg>
      <a href="mailto:#0">adarshtcreation@gmail.com</a>
    </div>
  </header>
  <!-- ==================== -->
</template>

<script setup>
import { onMounted, ref } from "vue";

const preloaderVisible = ref(true);

onMounted(() => {
  // Simulate window load event
  window.addEventListener("load", () => {
    const loader = document.getElementById("loader");
    const preloader = document.getElementById("preloader");

    if (loader) {
      loader.style.transition = "opacity 0.5s ease";
      loader.style.opacity = "0";

      setTimeout(() => {
        if (preloader) {
          preloader.style.transition = "opacity 0.5s ease";
          preloader.style.opacity = "0";

          // After fade out, remove the preloader from DOM
          setTimeout(() => {
            preloaderVisible.value = false;
          }, 500); // Match fade-out duration
        }
      }, 300); // Delay to match original jQuery code
    }
  });
});
</script>

<style lang="scss" scoped>
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1d1d1d;
  z-index: 500;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.no-js #preloader,
.oldie #preloader {
  display: none;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 6px;
  height: 6px;
  padding: 0;
  display: inline-block;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

#loader > div {
  content: "";
  background: #ffffff;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

#loader > div:nth-of-type(1) {
  left: 15px;
}

#loader > div:nth-of-type(3) {
  left: -15px;
}

/* dots fade */
.dots-fade > div {
  -webkit-animation: dots-fade 1.6s infinite ease;
  animation: dots-fade 1.6s infinite ease;
  animation-delay: 0.4s;
}

.dots-fade > div:nth-of-type(1) {
  animation-delay: 0.8s;
}

.dots-fade > div:nth-of-type(3) {
  animation-delay: 0s;
}

@-webkit-keyframes dots-fade {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0.2;
  }

  80% {
    opacity: 1;
  }
}

@keyframes dots-fade {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0.2;
  }

  80% {
    opacity: 1;
  }
}
</style>
